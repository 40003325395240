import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useCategory } from '../context/CategoryContext';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useToast } from '../hooks/useToast';
import { ConfirmModal } from './ConfirmModal';

const FormComponent = ({isOpen = false, close = () => {}, role = 'user'}) => {
  const { user } = useAuth();
  const [cafes, setCafes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [limit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const { categories, updateCategories } = useCategory();
  const { category } = useParams();
  const { showNotification } = useToast();
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    slug: '',
    description: '',
    category: categories[0]?.category_name || 'misc',
  });
  const [editingCafe, setEditingCafe] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmConfig, setConfirmConfig] = useState({ message: '', onConfirm: () => {} });
  const [cafeImage, setCafeImage] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [errors, setErrors] = useState({
    cafeImage: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Automatically update Slug when Name is changed
    if (name === 'name') {
      const formattedSlug = value
        .toLowerCase()
        .replace(/\s+/g, '') // Replace spaces with underscores
        .replace(/[^a-z0-9_]/g, ''); // Remove special characters, keep alphanumeric and underscores
      setFormData((prevState) => ({
        ...prevState,
        slug: formattedSlug,
      }));
    }
  };

  const uploadToR2 = async (file) => {
    try {
      // Convert file to base64
      const buffer = await file.arrayBuffer();
      const base64 = btoa(
        new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
      );

      // Get presigned URL from backend with file data
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/fileUpload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileName: file.name,
          fileType: file.type,
          fileBuffer: base64
        })
      });

      if (!response.ok) {
        throw new Error('Failed to get upload URL');
      }

      return await response.json();
    } catch (error) {
      console.error('Error uploading to R2:', error);
      throw error;
    }
  };

  const handleEditCafe = (cafe) => {
    setEditingCafe(cafe);
    setFormData({
      id: cafe.id,
      name: cafe.name,
      slug: cafe.slug,
      description: cafe.description,
      category: cafe.category,
    });
    if (cafe.image_url) {
      setPhotoPreview(cafe.image_url);
    }
    setIsModalOpen(true);
  }

  const handleDeleteCafe = (cafeId) => {
    const onConfirm = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/cafes/${cafeId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          showNotification('Cafe deleted successfully', 'success');
          fetchCafes();
        } else {
          showNotification('Failed to delete cafe', 'error');
        }
      } catch (error) {
        console.error('Error deleting cafe:', error);
        showNotification('Error deleting cafe', 'error');
      }
    }

    setConfirmConfig({ 
      message: 'Are you sure you want to delete this cafe?', 
      onConfirm 
    });
    setIsConfirmOpen(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageUrl = '';
    if (cafeImage) {
      const { url } = await uploadToR2(cafeImage);
      imageUrl = url;
    }

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/cafes`;
    const method = editingCafe ? 'PUT' : 'POST';

    if (formData.category === 'all' || !formData.category) {
      formData.category = categories[0].category_name;
    }

    const dataToSend = {
      ...formData,
      user_id: user?.user?.id,
      image_url: imageUrl,
      status: 'active',
      is_private: 0
    };

    try {
      const response = await fetch(apiUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        fetchCafes();
        resetForm();
        showNotification(
          `Cafe ${editingCafe ? 'updated' : 'created'} successfully`, 
          'success'
        );
        setIsModalOpen(false);
        close();
      } else {
        showNotification(
          `Error ${editingCafe ? 'updating' : 'creating'} cafe`, 
          'error'
        );
        console.error('Error submitting form', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  const resetForm = () => {
    setFormData({
      id: '',
      name: '',
      slug: '',
      description: '',
      category: categories[0]?.category_name || 'misc',
    });
    setCafeImage(null);
    setPhotoPreview(null);
    setEditingCafe(null);
  };

  const fetchCafes = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/cafes?limit=${limit}&offset=${(currentPage - 1) * limit}${category && category !== 'all' ? `&category=${category}` : ''
        }`
      );
      const data = await response.json();

      if (data && Array.isArray(data.cafes)) {
        setCafes(data.cafes);
        setTotalPages(Math.ceil(data.total / limit));
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching cafes:', error);
    } finally {
      setIsLoading(false);
    }
  }, [limit, currentPage, category]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/categories`);
        
        updateCategories(categoriesResponse.data.results);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCafes();
  }, [fetchCafes, currentPage, category]);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      category: category
    }));
  }, [category]);

  useEffect(() => {
    setCurrentPage(1);
  }, [category]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setErrors(prev => ({
          cafeImage: 'Image must be less than 5MB'
        }));
        return;
      }
      
      if (!file.type.startsWith('image/')) {
        setErrors(prev => ({
          cafeImage: 'File must be an image'
        }));
        return;
      }

      setCafeImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setErrors(prev => ({
        cafeImage: ''
      }));
    }
  };

  return (
    <div className="bg-gray-900 h-screen pt-10 flex flex-col">
      <ConfirmModal
        isOpen={isConfirmOpen}
        message={confirmConfig.message}
        onConfirm={() => {
          confirmConfig.onConfirm();
          setIsConfirmOpen(false);
        }}
        onCancel={() => setIsConfirmOpen(false)}
      />
      {/* Header Section */}
      <div className="bg-gray-800 p-4 px-8 md:p-6 border-b border-gray-700 sticky top-0 z-10 " style={{paddingInline: "6rem"}}>
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h2 className="text-lg md:text-xl font-semibold text-white">Created Cafes</h2>
          {/* {role === "admin" && ( */}
          <button
            onClick={() => setIsModalOpen(true)}
            className="hidden rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            New Cafe
          </button>
          {/* )} */}
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-4 md:p-6 overflow-auto">
        <div id="create_cafe" className="max-w-7xl mx-auto">
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <div className="flex flex-col items-center space-y-4">
                <svg className="animate-spin h-10 w-10 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span className="text-white">Loading cafes...</span>
              </div>
            </div>
          ) : (
            <div className="">
              <table className="min-w-full divide-y divide-gray-700">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">Name</th>
                    <th scope="col" className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-white">Slug</th>
                    <th scope="col" className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-white">Description</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">Category</th>
                    <th scope="col" className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-white">Private</th>
                    <th scope="col" className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-white">Created</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">Status</th>
                    {role === "admin" && (
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">Action</th>
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {cafes.map((cafe) => (
                    <tr key={cafe.id} className="hover:bg-gray-800">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                        <Link to={`/c/${cafe.slug}`} className="hover:text-indigo-400">
                          {cafe.name}
                        </Link>
                      </td>
                      <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-300">/c/{cafe.slug}</td>
                      <td className="hidden md:table-cell px-3 py-4 text-sm text-gray-300 truncate max-w-xs">{cafe.description}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{cafe.category}</td>
                      <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-300">{cafe.is_private ? 'Yes' : 'No'}</td>
                      <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {new Date(cafe.created_at).toLocaleDateString()}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{cafe.status}</td>
                      {role === "admin" && (
                        <td className="whitespace-nowrap px-3 py-4 text-sm space-x-2">
                          <button
                            onClick={() => handleEditCafe(cafe)}
                            className="p-1.5 bg-blue-600 text-white rounded hover:bg-blue-700 inline-flex items-center justify-center"
                            title="Edit"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleDeleteCafe(cafe.id)}
                            className="p-1.5 bg-red-600 text-white rounded hover:bg-red-700 inline-flex items-center justify-center"
                            title="Delete"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* Pagination */}
      <div className="bg-gray-800 p-4 border-t border-gray-700 sticky bottom-0">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="text-sm text-gray-400">
            Page {currentPage} of {totalPages}
          </div>
          <div className="flex gap-1">
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1 || isLoading}
              className="px-3 py-2 bg-gray-700 text-white rounded disabled:opacity-50 hover:bg-gray-600"
            >
              «
            </button>
            {[...Array(totalPages)].map((_, index) => {
              const pageNumber = index + 1;
              // Show first page, last page, current page, and pages around current page
              if (
                pageNumber === 1 ||
                pageNumber === totalPages ||
                (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2)
              ) {
                return (
                  <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    disabled={isLoading}
                    className={`px-3 py-2 rounded ${
                      currentPage === pageNumber
                        ? 'bg-indigo-600 text-white'
                        : 'bg-gray-700 text-white hover:bg-gray-600'
                    }`}
                  >
                    {pageNumber}
                  </button>
                );
              } else if (
                pageNumber === currentPage - 3 ||
                pageNumber === currentPage + 3
              ) {
                return (
                  <span
                    key={pageNumber}
                    className="px-3 py-2 text-gray-500"
                  >
                    ...
                  </span>
                );
              }
              return null;
            })}
            <button
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages || isLoading}
              className="px-3 py-2 bg-gray-700 text-white rounded disabled:opacity-50 hover:bg-gray-600"
            >
              »
            </button>
          </div>
        </div>
      </div>

      {/* Modal - Keep existing modal code but update the max-width for better mobile display */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
          <div className="fixed inset-0 bg-black bg-opacity-75"></div>
          <div className="bg-gray-800 rounded-lg p-4 md:p-6 z-50 w-full max-w-lg mx-auto relative">
            <h2 className="text-xl font-semibold leading-7 text-white mb-4">
              {editingCafe ? 'Edit Cafe' : 'Create Cafe'}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-white">
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 sm:row-span-2 flex flex-col items-center">
                  <div className="h-24 w-24 sm:h-32 sm:w-32 overflow-hidden rounded-full border-4 border-gray-600 mb-3 sm:mb-4">
                    <img
                      src={photoPreview || "https://cdn.thewatercooler.cafe/empty-photo.png"}
                      alt="Profile preview"
                      className="h-full w-full object-cover cursor-pointer"
                      onClick={() => fileInputRef.current?.click()}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => fileInputRef.current?.click()}
                    className="hidden sm:block rounded-md bg-white/10 px-3 py-1.5 sm:px-4 sm:py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                  >
                    {cafeImage ? 'Change Image' : 'Upload Image'}
                  </button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    id="cafeImage"
                    name="cafeImage"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                  {errors.cafeImage && (
                    <p className="text-red-500 text-sm mt-2">{errors.cafeImage}</p>
                  )}
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="slug" className="block text-sm font-medium leading-6 text-white">
                    Slug
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        /c/
                      </span>
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        value={formData.slug}
                        onChange={handleChange}
                        required
                        className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-full">
                  <label htmlFor="description" className="block text-sm font-medium leading-6 text-white">
                    Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      value={formData.description}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="category" className="block text-sm font-medium leading-6 text-white">
                    Category
                  </label>
                  <div className="mt-2">
                    <select
                      id="category"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      required
                      disabled={categories.length === 0}
                      className="block w-full rounded-md border-0 bg-[#2a3441] py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    >
                      {categories.length === 0 ? (
                        <option value="misc">Loading categories...</option>
                      ) : (
                        categories.map((category) => (
                          <option
                            key={category.id}
                            value={category.category_name.toLowerCase()}
                          >
                            {category.category_name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  onClick={() => {resetForm(); setIsModalOpen(false); close();}}
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormComponent;
