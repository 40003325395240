import React, { createContext, useState, useContext } from 'react';

const SidebarContext = createContext({
  clearSidebarInfo: () => {},
  roomInfo: { name: '', description: '', image_url: '', total_members: 0, online_members: 0, slug: '', category: '' },
  updateRoomInfo: () => {},
});

export function SidebarProvider({ children }) {
  const [roomInfo, setRoomInfo] = useState({
    name: '',
    description: '',
    image_url: '',
    total_members: 0,
    online_members: 0,
    slug: 'lobby',
    category: ''
  });

  const clearSidebarInfo = () => {
    setRoomInfo({ name: '', description: '', image_url: '', total_members: 0, online_members: 0, slug: 'lobby', category: '' });
  };

  const updateRoomInfo = (info) => {
    setRoomInfo(prevInfo => ({
      ...prevInfo,
      ...info
    }));
  };

  const value = {
    clearSidebarInfo,
    roomInfo,
    updateRoomInfo,
  };

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  );
}

export function useSidebar() {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar must be used within an SidebarProvider');
  }
  return context;
} 