import React from 'react';
import { NavLink, Route, Routes, Navigate } from 'react-router-dom';
import CafeUsers from './CafeUsers';
import Reports from './Reports';

const AdminPanel = () => {
  return (
    <div className="p-6 bg-gray-900 min-h-screen">
      <div className="max-w-7xl mx-auto">
        {/* <h1 className="text-2xl font-bold text-white mb-6">Admin Panel</h1> */}
        <nav className="mb-6">
        <NavLink
            to="/admin/cafe_users"
            className={({ isActive }) =>
              isActive ? "text-2xl font-bold text-green-500 mr-4" : "text-2xl font-bold text-white mr-4"
            }
          >
            Cafe Users
          </NavLink>
          <NavLink
            to="/admin/reports"
            className={({ isActive }) =>
              isActive ? "text-2xl font-bold text-green-500" : "text-2xl font-bold text-white"
            }
          >
            Reports
          </NavLink>
        </nav>
        <Routes>
          <Route path="cafe_users" element={<CafeUsers />} />
          <Route path="reports" element={<Reports />} />
          <Route path="/" element={<Navigate to="/admin/cafe_users" />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminPanel;