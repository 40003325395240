import React, { useState, useEffect, useCallback } from 'react';
// import { useAdminAuth } from '../context/AdminAuthContext';

const CafeUsers = () => {
  const [users, setUsers] = useState([]);
  const [selectedCafe, setSelectedCafe] = useState('lobby');
  const [cafes, setCafes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);

  const fetchCafes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/cafes`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminAuthToken')}`,
        }
      });
      const data = await response.json();
      setCafes(data.cafes);
    } catch (error) {
      console.error('Failed to fetch cafes:', error);
    }
  };

  const fetchCafeUsers = useCallback(async () => {
    try {
      const offset = (currentPage - 1) * limit;
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/cafes/${selectedCafe}/users?limit=${limit}&offset=${offset}`, 
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminAuthToken')}`,
          }
        }
      );
      const data = await response.json();
      setUsers(data.users);
      setTotalPages(Math.ceil(data.total / limit));
      // setLoading(false);
    } catch (error) {
      console.error('Failed to fetch cafe users:', error);
    }
  }, [selectedCafe, currentPage, limit]);

  useEffect(() => {
    fetchCafes();
    fetchCafeUsers();
  }, [selectedCafe, currentPage, fetchCafeUsers]);

  const handleModeratorChange = async (userId, isModerator) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/cafes/${selectedCafe}/users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('adminAuthToken')}`,
        },
        body: JSON.stringify({ is_moderator: isModerator ? 1 : 0 })
      });

      if (response.ok) {
        fetchCafeUsers();
      }
    } catch (error) {
      console.error('Failed to update moderator status:', error);
    }
  };

  return (
    // <div className="bg-gray-900 min-h-screen">
      <div className="max-w-7xl mx-auto">
        {/* <h1 className="text-2xl font-bold text-white mb-6">Cafe Users</h1> */}
        
        {/* Cafe Selector */}
        <div className="mb-2 flex items-center gap-4 bg-gray-800 p-4 rounded-lg">
          <label htmlFor="cafeSelect" className="text-white font-medium">Please Select Cafe</label>
          <select
            id="cafeSelect"
            value={selectedCafe}
            onChange={(e) => {
              setSelectedCafe(e.target.value)
              setCurrentPage(1)
            }}
            className="bg-gray-700 text-white rounded px-4 py-2 min-w-[200px]"
          >
            <option value="lobby">Lobby</option>
            {cafes.map((cafe) => (
              <option key={cafe.id} value={cafe.slug}>
                {cafe.name}
              </option>
            ))}
          </select>
        </div>

        {/* Users Table */}
        <div className="bg-gray-800 rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-700">
            <thead className="bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider">
                  Username
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider">
                  Photo
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-200 uppercase tracking-wider">
                  Role
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {users.map((user) => (
                <tr key={user.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-200">
                    {user.username}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-200">
                    {user.email || 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.profile_photo ? (
                      <img 
                        src={user.profile_photo} 
                        alt={user.username}
                        className="h-10 w-10 rounded-full"
                      />
                    ) : (
                      <div className="h-10 w-10 rounded-full bg-gray-600" />
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <select
                      className="bg-gray-700 text-white rounded px-2 py-1"
                      value={user.is_moderator ? 'moderator' : 'user'}
                      onChange={(e) => handleModeratorChange(user.id, e.target.value === 'moderator')}
                    >
                      <option value="user">User</option>
                      <option value="moderator">Moderator</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bg-gray-800 px-6 py-4 border-t border-gray-700">
          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-400">
              Page {currentPage} of {totalPages}
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                disabled={currentPage === 1}
                className="px-4 py-2 bg-gray-700 text-white rounded disabled:opacity-50 hover:bg-gray-600"
              >
                Previous
              </button>
              <button
                onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                disabled={currentPage === totalPages}
                className="px-4 py-2 bg-gray-700 text-white rounded disabled:opacity-50 hover:bg-gray-600"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

    // </div>
  );
};

export default CafeUsers;