import React, { useEffect, useState, useCallback } from 'react';
import Modal from '../Modal';
import { useAdminAuth } from '../../context/AdminAuthContext';
import { ConfirmModal } from '../ConfirmModal';
import VideoPlayer from '../VideoPlayer';

const Reports = () => {
  const [reportedImages, setReportedImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedType, setSelectedType] = useState('all');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmConfig, setConfirmConfig] = useState({ message: '', onConfirm: () => {} });
  const { admin } = useAdminAuth();

  const types = [
    { id: 'all', label: 'All Reports' },
    { id: '1', label: 'Spam' },
    { id: '2', label: 'Off-Topic' },
    { id: '3', label: 'Illegal Image' }
  ];

  const fetchReportedImages = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/reports?type=${selectedType}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminAuthToken')}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.success) {
        setReportedImages(data.reports);
      } else {
        console.error('Failed to fetch reported images:', data.error);
      }
    } catch (error) {
      console.error('Error fetching reported images:', error);
    }
  }, [selectedType]);

  useEffect(() => {
    fetchReportedImages();
  }, [fetchReportedImages]);

  const handleReportAction = (actionType) => {
    if (selectedImageIndex === null) return;

    const onConfirm = async () => {
      try {
        const image = reportedImages[selectedImageIndex];
        const payload = {
          type: "report",
          messageId: image.message_id,
          userId: admin?.user?.id,
          url: image.url,
          actionType
        };
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/reports`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminAuthToken')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        const data = await response.json();
        if (data.success) {
          setReportedImages(prevImages => prevImages.filter((_, index) => index !== selectedImageIndex));
          setSelectedImageIndex(null);
        } else {
          console.error('Failed to update report status:', data.error);
        }
      } catch (error) {
        console.error('Error updating report status:', error);
      }
    };

    setConfirmConfig({
      message: `Are you sure you want to ${actionType} this report?`,
      onConfirm
    });
    setIsConfirmOpen(true);
  };

  return (
    <div className="bg-gray-900">
      <div className="max-w-7xl mx-auto pt-4">
        <div className="mb-8 flex items-center gap-4 bg-gray-800 p-4 rounded-lg w-full">
          <label htmlFor="cafeSelect" className="text-white font-medium">Please Select Type</label>
          <select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            className="bg-gray-700 text-white rounded px-4 py-2 min-w-[200px]"
          >
            {types.map(reason => (
              <option key={reason.id} value={reason.id}>
                {reason.label}
              </option>
            ))}
          </select>
        </div>
        <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-2 sm:gap-4">
          {reportedImages.map((image, index) => (
            <div
              key={index}
              className="aspect-square relative cursor-pointer hover:opacity-90 transition-opacity"
              onClick={() => setSelectedImageIndex(index)}
            >
              {image.url.toLowerCase().endsWith('.webm') ? (
                <video
                  src={image.url}
                  className="absolute rounded-md inset-0 w-full h-full object-cover"
                  muted
                  loop
                  autoPlay
                  playsInline
                />
              ) : (
                <img
                  src={image.url}
                  alt="preview"
                  className="absolute rounded-md inset-0 w-full h-full object-cover"
                />
              )}
            </div>
          ))}
        </div>
        {reportedImages.length === 0 && (
          <div className="col-span-full text-white text-center text-sm sm:text-base py-8">
            No reported images found
          </div>
        )}
      </div>

      <Modal
        isOpen={selectedImageIndex !== null}
        onClose={() => setSelectedImageIndex(null)}
        onReport={() => handleReportAction('ignore')}
        isReported={true}
        isOwner={false}
        isModerator={true}
        onDelete={() => handleReportAction('delete')}
        size="xl"
      >
        {selectedImageIndex !== null && (
          <div className="flex flex-col items-center gap-4 w-full mx-auto p-2 sm:p-4">
            <div className="flex items-center justify-between w-full">
              <button
                onClick={() => setSelectedImageIndex((prev) => (prev > 0 ? prev - 1 : reportedImages.length - 1))}
                className="bg-black bg-opacity-50 text-white p-2 sm:p-4 rounded-lg hover:bg-opacity-75 min-w-[36px] sm:min-w-[48px]"
              >
                &#8249;
              </button>

              <div className="flex-1 flex flex-col items-center relative group px-2 sm:px-4">
                {reportedImages[selectedImageIndex].url.toLowerCase().endsWith('.webm') ? (
                  <VideoPlayer
                    src={reportedImages[selectedImageIndex].url}
                    className="w-full max-w-[250px] sm:max-w-[800px] md:max-w-[1000px] h-auto max-h-[80vh] object-contain rounded-lg"
                    controls
                  />
                ) : (
                  <img
                    src={reportedImages[selectedImageIndex].url}
                    alt=""
                    className="w-full max-w-[250px] sm:max-w-[800px] md:max-w-[1000px] h-auto max-h-[80vh] object-contain rounded-lg"
                  />
                )}
              </div>

              <button
                onClick={() => setSelectedImageIndex((prev) => (prev < reportedImages.length - 1 ? prev + 1 : 0))}
                className="bg-black bg-opacity-50 text-white p-2 sm:p-4 rounded-lg hover:bg-opacity-75 min-w-[36px] sm:min-w-[48px]"
              >
                &#8250;
              </button>
            </div>
          </div>
        )}
      </Modal>

      <ConfirmModal
        isOpen={isConfirmOpen}
        message={confirmConfig.message}
        onConfirm={() => {
          confirmConfig.onConfirm();
          setIsConfirmOpen(false);
        }}
        onCancel={() => setIsConfirmOpen(false)}
      />
    </div>
  );
};

export default Reports;